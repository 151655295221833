












































































































































































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
// import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
// import accountModule from '@/store/modules/accountModule';
// import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
// import tasqsListModule from '@/store/modules/tasqsListModule';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import { setTimeout } from 'timers';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class Groups extends Vue {
  @Prop({ type: Object, required: false }) wellEventDetails?: any;

    windowWidth = window.innerWidth;

     dataLoading = true;

     get isMobile() {
       return this.windowWidth < 769;
     }

     created() {
       console.log(this.wellEventDetails);
       setTimeout(() => {
         this.dataLoading = false;
       }, 1000);
     }
}

